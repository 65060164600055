import {createInstance} from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import type {Site} from '../data/types';

import {default as options, formatters, urlFormatterErrorMsg} from './i18n';
import env from './utils/env';

export interface InstanceProps {
  site: Site;
  pricingLocale?: string;
  storage?: any;
  namespaces?: string | string[];
  resources?: any;
}

/**
 * Custom logger config to workaround i18n hardcoded warn method for formatters
 *
 * @note Currently only passed to the client instance to avoid excessive logging
 * in the server logs.
 * @see https://www.i18next.com/misc/creating-own-plugins#logger
 */
const customLogger = {
  type: 'logger' as const,
  /**
   * noop to avoid excessive logging when `debug: true` is enabled
   */
  log() {},
  /**
   * i18n catches all errors from custom formatters and hardcodes the logger to
   * use `warn`. In order to surface an error to the app when an invalid
   * `interpolationkey` is used in `url()` we need to hijack the `warn` method
   * and throw an error when the message matches the error thrown within `url()`.
   *
   * @see https://github.com/i18next/i18next/blob/v23.6.0/src/Formatter.js#L112C1-L128C10
   */
  warn(err: Error[]) {
    if (err[0]?.message?.includes(urlFormatterErrorMsg.start)) {
      throw new Error(err[0].message);
    }

    console.warn(err[0]);
  },
  error(err: Error[]) {
    throw new Error(err[0]?.message);
  },
};

export const getClientInstance = ({
  site,
  pricingLocale,
  resources,
}: InstanceProps) => {
  const instance = createInstance();
  const {locale} = site;
  instance
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(customLogger)
    .init({
      ...options(pricingLocale || locale),
      debug: env('SHOPIFY_APP_ENV') !== 'production',
      lng: locale,
      resources,
      detection: {
        // Here only enable htmlTag detection, we'll detect the language only
        // server-side with remix-i18next, by using the `<html lang>` attribute
        // we can communicate to the client the language detected server-side
        order: ['htmlTag'],
        // Because we only use htmlTag, there's no reason to cache the language
        // on the browser, so we disable it
        caches: [],
      },
    });

  formatters(instance, site);

  return instance;
};
