import type {PropsWithChildren} from 'react';
import {I18nextProvider} from 'react-i18next';

import {getClientInstance} from '@/i18nInstance';

import {extractSiteData, SiteDataContext} from './SiteData';
import {extractPricingData, PricingDataContext} from './PricingData';
import {extractPageData, PageDataContext} from './PageData';
import I18N from './I18N';

export default function Provider({
  data,
  children,
}: PropsWithChildren<{data: any}>) {
  const siteData = extractSiteData(data);
  const pricingData = extractPricingData(data);
  const pageData = extractPageData(data);

  const i18nInstance = getClientInstance({
    site: siteData.site,
    resources: data.translations,
    pricingLocale: pricingData.pricingSite?.locale,
  });

  return (
    <SiteDataContext.Provider value={siteData}>
      <PricingDataContext.Provider value={pricingData}>
        <I18nextProvider i18n={i18nInstance}>
          <PageDataContext.Provider value={pageData}>
            <I18N
              experimentVariationId={data?.experimentVariationId}
              canonicalUrl={data?.canonicalUrl}
            >
              {children}
            </I18N>
          </PageDataContext.Provider>
        </I18nextProvider>
      </PricingDataContext.Provider>
    </SiteDataContext.Provider>
  );
}
